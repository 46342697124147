import { default as indexqksRg9oJ1WMeta } from "/usr/src/apps/base/src/pages/index.vue?macro=true";
import { default as _91_46_46_46404_93dMgKFsN6Q6Meta } from "/usr/src/packages/error-handling/pages/[...404].vue?macro=true";
import { default as about4C1BTiVyMhMeta } from "/usr/src/apps/base/src/pages/about.vue?macro=true";
import { default as accessibility08XNiHGQ0oMeta } from "/usr/src/apps/base/src/pages/accessibility.vue?macro=true";
import { default as contactwZAZ1OhQATMeta } from "/usr/src/apps/base/src/pages/contact.vue?macro=true";
import { default as datasecurityk9e8JnurInMeta } from "/usr/src/apps/base/src/pages/datasecurity.vue?macro=true";
import { default as disclosureIQD52U35CiMeta } from "/usr/src/apps/base/src/pages/disclosure.vue?macro=true";
import { default as forgotpassword3Ou1x7Xjk4Meta } from "/usr/src/apps/base/src/pages/forgotpassword.vue?macro=true";
import { default as _91subId_93giIOKomImsMeta } from "/usr/src/packages/funds/pages/funds/categories/[[id]]/sub/[subId].vue?macro=true";
import { default as _91_91id_93_93tp1gATOtAhMeta } from "/usr/src/packages/funds/pages/funds/categories/[[id]].vue?macro=true";
import { default as _91_91secondaryId_93_93doHrMYdqh9Meta } from "/usr/src/packages/funds/pages/funds/categories/list/[id]/[subId]/[[secondaryId]].vue?macro=true";
import { default as indexpfTZWeLv3nMeta } from "/usr/src/packages/funds/pages/funds/categories/list/[id]/index.vue?macro=true";
import { default as categorieshwYG5zFBPqMeta } from "/usr/src/packages/funds/pages/funds/categories.vue?macro=true";
import { default as _91id_93wcADWhPbSjMeta } from "/usr/src/packages/funds/pages/funds/details/[id].vue?macro=true";
import { default as indexPUVZVGHyIjMeta } from "/usr/src/packages/funds/pages/funds/index.vue?macro=true";
import { default as indexZpg6WWboeuMeta } from "/usr/src/packages/funds/pages/funds/list/index.vue?macro=true";
import { default as indexFHfBmuIrrqMeta } from "/usr/src/packages/funds/pages/funds/manager/[[id]]/list/index.vue?macro=true";
import { default as _91_91id_93_93GQCyBsRXOLMeta } from "/usr/src/packages/funds/pages/funds/manager/[[id]].vue?macro=true";
import { default as manager6mT3ksJ8OwMeta } from "/usr/src/packages/funds/pages/funds/manager.vue?macro=true";
import { default as indexKaP2VkdS0RMeta } from "/usr/src/packages/funds/pages/funds/search/index.vue?macro=true";
import { default as fundsJniTMmEIW7Meta } from "/usr/src/packages/funds/pages/funds.vue?macro=true";
import { default as kranot_45gidurnWR26FjmhmMeta } from "/usr/src/apps/finq-finance/src/pages/kranot-gidur.vue?macro=true";
import { default as _91_91group_93_934w1d1wdPGbMeta } from "/usr/src/packages/kyc/pages/kyc/[type]/[[group]].vue?macro=true";
import { default as stepsHDNOePwTEnMeta } from "/usr/src/packages/kyc/pages/kyc/[type]/steps.vue?macro=true";
import { default as _91type_93Zgq61ZsU9DMeta } from "/usr/src/packages/kyc/pages/kyc/[type].vue?macro=true";
import { default as donePxbdkf3XIxMeta } from "/usr/src/packages/kyc/pages/kyc/done.vue?macro=true";
import { default as indexlHo5lbSUxmMeta } from "/usr/src/packages/kyc/pages/kyc/index.vue?macro=true";
import { default as loginYIEPQC6mhGMeta } from "/usr/src/apps/base/src/pages/login.vue?macro=true";
import { default as partnersnKP1uP9IicMeta } from "/usr/src/apps/base/src/pages/partners.vue?macro=true";
import { default as passwordresetawaitFAii2hbFCfMeta } from "/usr/src/apps/base/src/pages/passwordresetawait.vue?macro=true";
import { default as _91company_93iWeaW7O18lMeta } from "/usr/src/packages/pension/pages/pension/depot/finqfull/companies/[company].vue?macro=true";
import { default as index7V9IkVqMQaMeta } from "/usr/src/packages/pension/pages/pension/depot/finqfull/companies/index.vue?macro=true";
import { default as indexvOJnJJHuf3Meta } from "/usr/src/packages/pension/pages/pension/depot/finqfull/index.vue?macro=true";
import { default as _91specialty_93EMJ8NGR8qiMeta } from "/usr/src/packages/pension/pages/pension/depot/finqfull/specialization/[specialty].vue?macro=true";
import { default as index5EOUCdyNX8Meta } from "/usr/src/packages/pension/pages/pension/depot/finqfull/specialization/index.vue?macro=true";
import { default as _91fundHash_93EFQ29CzgCKMeta } from "/usr/src/packages/pension/pages/pension/depot/finqfull/watch/[fundHash].vue?macro=true";
import { default as indexiSIKEil1ZxMeta } from "/usr/src/packages/pension/pages/pension/depot/finqfull/watch/index.vue?macro=true";
import { default as indexUOvEfDpeO0Meta } from "/usr/src/packages/pension/pages/pension/depot/index.vue?macro=true";
import { default as companiesOMujoLmf23Meta } from "/usr/src/packages/pension/pages/pension/depot/leading-funds/companies.vue?macro=true";
import { default as indexufVtMGX5vzMeta } from "/usr/src/packages/pension/pages/pension/depot/leading-funds/index.vue?macro=true";
import { default as depotaPbp8tJMbrMeta } from "/usr/src/packages/pension/pages/pension/depot.vue?macro=true";
import { default as _91_91fid_93_93B3CL5Ky413Meta } from "/usr/src/packages/portfolios/pages/portfolios/consult/details/[[pid]]/[[fid]].vue?macro=true";
import { default as _91_91pid_93_93FAQHdTbWrNMeta } from "/usr/src/packages/portfolios/pages/portfolios/consult/details/[[pid]].vue?macro=true";
import { default as indexCuvpEcNESTMeta } from "/usr/src/packages/portfolios/pages/portfolios/favorites/index.vue?macro=true";
import { default as indexaZoVOhZwTVMeta } from "/usr/src/packages/portfolios/pages/portfolios/index.vue?macro=true";
import { default as lobbyVVzKDVXQ0XMeta } from "/usr/src/packages/portfolios/pages/portfolios/lobby.vue?macro=true";
import { default as _91_91fid_93_93olluoChfBKMeta } from "/usr/src/packages/portfolios/pages/portfolios/store/details/[[pid]]/[[fid]].vue?macro=true";
import { default as _91_91pid_93_935JUUTaXGU6Meta } from "/usr/src/packages/portfolios/pages/portfolios/store/details/[[pid]].vue?macro=true";
import { default as indexetdbuDprAYMeta } from "/usr/src/packages/portfolios/pages/portfolios/store/index.vue?macro=true";
import { default as storenavOVDyujfMeta } from "/usr/src/packages/portfolios/pages/portfolios/store.vue?macro=true";
import { default as portfoliosjxRGDJlRxLMeta } from "/usr/src/packages/portfolios/pages/portfolios.vue?macro=true";
import { default as _91_91productId_93_93hqPFitOqkYMeta } from "/usr/src/packages/pricing/pages/pricing/[[productId]].vue?macro=true";
import { default as pricingOIBELzIw3pMeta } from "/usr/src/packages/pricing/pages/pricing.vue?macro=true";
import { default as privacyW7FWgOcv9fMeta } from "/usr/src/apps/base/src/pages/privacy.vue?macro=true";
import { default as infoFL8Bz5DpZJMeta } from "/usr/src/apps/base/src/pages/profile/info.vue?macro=true";
import { default as _91_91id_93_938xxf54ZtMqMeta } from "/usr/src/apps/finq-finance/src/pages/profile/investments/funds/[[id]].vue?macro=true";
import { default as indexmc0Fx1pJFqMeta } from "/usr/src/apps/finq-finance/src/pages/profile/investments/index.vue?macro=true";
import { default as messages7UYmgPe1CQMeta } from "/usr/src/apps/finq-finance/src/pages/profile/investments/messages.vue?macro=true";
import { default as reportsQKsxN8v9rlMeta } from "/usr/src/apps/finq-finance/src/pages/profile/investments/reports.vue?macro=true";
import { default as transactionsn9eMzhgE0jMeta } from "/usr/src/apps/finq-finance/src/pages/profile/investments/transactions.vue?macro=true";
import { default as investmentsKaKYZu24t3Meta } from "/usr/src/apps/finq-finance/src/pages/profile/investments.vue?macro=true";
import { default as profilezv8G5W5pJQMeta } from "/usr/src/apps/base/src/pages/profile.vue?macro=true";
import { default as signupD0zLV4v9noMeta } from "/usr/src/apps/base/src/pages/signup.vue?macro=true";
import { default as finqfull2rvSY7bCOYMeta } from "/usr/src/packages/stocks/pages/stocks/depot/finqfull.vue?macro=true";
import { default as indexD0CTvv9iYrMeta } from "/usr/src/packages/stocks/pages/stocks/depot/index.vue?macro=true";
import { default as depotozn4ObMpKAMeta } from "/usr/src/packages/stocks/pages/stocks/depot.vue?macro=true";
import { default as indexmzXPjhq5EgMeta } from "/usr/src/packages/stocks/pages/stocks/index.vue?macro=true";
import { default as indexPDJrGGTxBuMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/index.vue?macro=true";
import { default as returnsnxcTGiYrnyMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/returns.vue?macro=true";
import { default as transactionslgjJ1jRxX6Meta } from "/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/transactions.vue?macro=true";
import { default as _91portfolioType_93Lz5OTVGOUJMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType].vue?macro=true";
import { default as _91id_93xL6fM6Wr70Meta } from "/usr/src/packages/stocks/pages/stocks/portfolios/details/[id].vue?macro=true";
import { default as detailsxa2DsXpMPrMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/details.vue?macro=true";
import { default as indexsNto0OwKRYMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/index.vue?macro=true";
import { default as _91sectorId_93oZzOWsL8HcMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/sectors/[sectorId].vue?macro=true";
import { default as indexvuxyYCf9xrMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/sectors/index.vue?macro=true";
import { default as sectorsLTaJFptYjnMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/sectors.vue?macro=true";
import { default as indexRIwpw4c1lXMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/snp500/index.vue?macro=true";
import { default as sectors74tcQjPqt4Meta } from "/usr/src/packages/stocks/pages/stocks/portfolios/snp500/sectors.vue?macro=true";
import { default as snp500mPrgrMS26sMeta } from "/usr/src/packages/stocks/pages/stocks/portfolios/snp500.vue?macro=true";
import { default as portfolioszi0VENqbE6Meta } from "/usr/src/packages/stocks/pages/stocks/portfolios.vue?macro=true";
import { default as searchT1B2Y8KjdfMeta } from "/usr/src/packages/stocks/pages/stocks/search.vue?macro=true";
import { default as top20QVDMEgSsSmMeta } from "/usr/src/packages/stocks/pages/stocks/top20.vue?macro=true";
import { default as stocksYNZBSUl7vEMeta } from "/usr/src/packages/stocks/pages/stocks.vue?macro=true";
import { default as callback0Nh93rez4dMeta } from "/usr/src/apps/base/src/pages/subscribe/callback.vue?macro=true";
import { default as termsofusel5AzRHu0H7Meta } from "/usr/src/apps/base/src/pages/termsofuse.vue?macro=true";
import { default as trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta } from "/usr/src/apps/base/src/pages/trademark-copyright-intellectual-property.vue?macro=true";
import { default as verify2fhYk5060fMeta } from "/usr/src/apps/base/src/pages/verify.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexqksRg9oJ1WMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/index.vue")
  },
  {
    name: "404___he",
    path: "/he/:404(.*)*",
    meta: _91_46_46_46404_93dMgKFsN6Q6Meta || {},
    component: () => import("/usr/src/packages/error-handling/pages/[...404].vue")
  },
  {
    name: "404___en",
    path: "/en/:404(.*)*",
    meta: _91_46_46_46404_93dMgKFsN6Q6Meta || {},
    component: () => import("/usr/src/packages/error-handling/pages/[...404].vue")
  },
  {
    name: "about___he",
    path: "/he/about",
    meta: about4C1BTiVyMhMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/about.vue")
  },
  {
    name: "about___en",
    path: "/en/about",
    meta: about4C1BTiVyMhMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/about.vue")
  },
  {
    name: "accessibility___he",
    path: "/he/accessibility",
    component: () => import("/usr/src/apps/base/src/pages/accessibility.vue")
  },
  {
    name: "accessibility___en",
    path: "/en/accessibility",
    component: () => import("/usr/src/apps/base/src/pages/accessibility.vue")
  },
  {
    name: "contact___he",
    path: "/he/contact",
    meta: contactwZAZ1OhQATMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    meta: contactwZAZ1OhQATMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/contact.vue")
  },
  {
    name: "datasecurity___he",
    path: "/he/datasecurity",
    meta: datasecurityk9e8JnurInMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/datasecurity.vue")
  },
  {
    name: "datasecurity___en",
    path: "/en/datasecurity",
    meta: datasecurityk9e8JnurInMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/datasecurity.vue")
  },
  {
    name: "disclosure___he",
    path: "/he/disclosure",
    component: () => import("/usr/src/apps/base/src/pages/disclosure.vue")
  },
  {
    name: "disclosure___en",
    path: "/en/disclosure",
    component: () => import("/usr/src/apps/base/src/pages/disclosure.vue")
  },
  {
    name: "forgotpassword___he",
    path: "/he/forgotpassword",
    meta: forgotpassword3Ou1x7Xjk4Meta || {},
    component: () => import("/usr/src/apps/base/src/pages/forgotpassword.vue")
  },
  {
    name: "forgotpassword___en",
    path: "/en/forgotpassword",
    meta: forgotpassword3Ou1x7Xjk4Meta || {},
    component: () => import("/usr/src/apps/base/src/pages/forgotpassword.vue")
  },
  {
    name: fundsJniTMmEIW7Meta?.name,
    path: "/he/funds",
    meta: fundsJniTMmEIW7Meta || {},
    component: () => import("/usr/src/packages/funds/pages/funds.vue"),
    children: [
  {
    name: "funds-categories___he",
    path: "categories",
    meta: categorieshwYG5zFBPqMeta || {},
    component: () => import("/usr/src/packages/funds/pages/funds/categories.vue"),
    children: [
  {
    name: "funds-categories-id___he",
    path: ":id?",
    component: () => import("/usr/src/packages/funds/pages/funds/categories/[[id]].vue"),
    children: [
  {
    name: "funds-categories-id-sub-subId___he",
    path: "sub/:subId()",
    meta: _91subId_93giIOKomImsMeta || {},
    component: () => import("/usr/src/packages/funds/pages/funds/categories/[[id]]/sub/[subId].vue")
  }
]
  },
  {
    name: "funds-categories-list-id-subId-secondaryId___he",
    path: "list/:id()/:subId()/:secondaryId?",
    meta: _91_91secondaryId_93_93doHrMYdqh9Meta || {},
    component: () => import("/usr/src/packages/funds/pages/funds/categories/list/[id]/[subId]/[[secondaryId]].vue")
  },
  {
    name: "funds-categories-list-id___he",
    path: "list/:id()",
    meta: indexpfTZWeLv3nMeta || {},
    component: () => import("/usr/src/packages/funds/pages/funds/categories/list/[id]/index.vue")
  }
]
  },
  {
    name: "funds-details-id___he",
    path: "details/:id()",
    meta: _91id_93wcADWhPbSjMeta || {},
    component: () => import("/usr/src/packages/funds/pages/funds/details/[id].vue")
  },
  {
    name: "funds___he",
    path: "",
    meta: indexPUVZVGHyIjMeta || {},
    component: () => import("/usr/src/packages/funds/pages/funds/index.vue")
  },
  {
    name: "funds-list___he",
    path: "list",
    meta: indexZpg6WWboeuMeta || {},
    component: () => import("/usr/src/packages/funds/pages/funds/list/index.vue")
  },
  {
    name: "funds-manager___he",
    path: "manager",
    meta: manager6mT3ksJ8OwMeta || {},
    component: () => import("/usr/src/packages/funds/pages/funds/manager.vue"),
    children: [
  {
    name: "funds-manager-id___he",
    path: ":id?",
    meta: _91_91id_93_93GQCyBsRXOLMeta || {},
    component: () => import("/usr/src/packages/funds/pages/funds/manager/[[id]].vue"),
    children: [
  {
    name: "funds-manager-id-list___he",
    path: "list",
    meta: indexFHfBmuIrrqMeta || {},
    component: () => import("/usr/src/packages/funds/pages/funds/manager/[[id]]/list/index.vue")
  }
]
  }
]
  },
  {
    name: "funds-search___he",
    path: "search",
    component: () => import("/usr/src/packages/funds/pages/funds/search/index.vue")
  }
]
  },
  {
    name: fundsJniTMmEIW7Meta?.name,
    path: "/en/funds",
    meta: fundsJniTMmEIW7Meta || {},
    component: () => import("/usr/src/packages/funds/pages/funds.vue"),
    children: [
  {
    name: "funds-categories___en",
    path: "categories",
    meta: categorieshwYG5zFBPqMeta || {},
    component: () => import("/usr/src/packages/funds/pages/funds/categories.vue"),
    children: [
  {
    name: "funds-categories-id___en",
    path: ":id?",
    component: () => import("/usr/src/packages/funds/pages/funds/categories/[[id]].vue"),
    children: [
  {
    name: "funds-categories-id-sub-subId___en",
    path: "sub/:subId()",
    meta: _91subId_93giIOKomImsMeta || {},
    component: () => import("/usr/src/packages/funds/pages/funds/categories/[[id]]/sub/[subId].vue")
  }
]
  },
  {
    name: "funds-categories-list-id-subId-secondaryId___en",
    path: "list/:id()/:subId()/:secondaryId?",
    meta: _91_91secondaryId_93_93doHrMYdqh9Meta || {},
    component: () => import("/usr/src/packages/funds/pages/funds/categories/list/[id]/[subId]/[[secondaryId]].vue")
  },
  {
    name: "funds-categories-list-id___en",
    path: "list/:id()",
    meta: indexpfTZWeLv3nMeta || {},
    component: () => import("/usr/src/packages/funds/pages/funds/categories/list/[id]/index.vue")
  }
]
  },
  {
    name: "funds-details-id___en",
    path: "details/:id()",
    meta: _91id_93wcADWhPbSjMeta || {},
    component: () => import("/usr/src/packages/funds/pages/funds/details/[id].vue")
  },
  {
    name: "funds___en",
    path: "",
    meta: indexPUVZVGHyIjMeta || {},
    component: () => import("/usr/src/packages/funds/pages/funds/index.vue")
  },
  {
    name: "funds-list___en",
    path: "list",
    meta: indexZpg6WWboeuMeta || {},
    component: () => import("/usr/src/packages/funds/pages/funds/list/index.vue")
  },
  {
    name: "funds-manager___en",
    path: "manager",
    meta: manager6mT3ksJ8OwMeta || {},
    component: () => import("/usr/src/packages/funds/pages/funds/manager.vue"),
    children: [
  {
    name: "funds-manager-id___en",
    path: ":id?",
    meta: _91_91id_93_93GQCyBsRXOLMeta || {},
    component: () => import("/usr/src/packages/funds/pages/funds/manager/[[id]].vue"),
    children: [
  {
    name: "funds-manager-id-list___en",
    path: "list",
    meta: indexFHfBmuIrrqMeta || {},
    component: () => import("/usr/src/packages/funds/pages/funds/manager/[[id]]/list/index.vue")
  }
]
  }
]
  },
  {
    name: "funds-search___en",
    path: "search",
    component: () => import("/usr/src/packages/funds/pages/funds/search/index.vue")
  }
]
  },
  {
    name: "index___he",
    path: "/he",
    meta: indexqksRg9oJ1WMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexqksRg9oJ1WMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/index.vue")
  },
  {
    name: "kranot-gidur___he",
    path: "/he/kranot-gidur",
    meta: kranot_45gidurnWR26FjmhmMeta || {},
    component: () => import("/usr/src/apps/finq-finance/src/pages/kranot-gidur.vue")
  },
  {
    name: "kranot-gidur___en",
    path: "/en/kranot-gidur",
    meta: kranot_45gidurnWR26FjmhmMeta || {},
    component: () => import("/usr/src/apps/finq-finance/src/pages/kranot-gidur.vue")
  },
  {
    name: "kyc-type___he",
    path: "/he/kyc/:type()",
    meta: _91type_93Zgq61ZsU9DMeta || {},
    component: () => import("/usr/src/packages/kyc/pages/kyc/[type].vue"),
    children: [
  {
    name: "kyc-type-group___he",
    path: ":group?",
    meta: _91_91group_93_934w1d1wdPGbMeta || {},
    component: () => import("/usr/src/packages/kyc/pages/kyc/[type]/[[group]].vue")
  },
  {
    name: "kyc-type-steps___he",
    path: "steps",
    meta: stepsHDNOePwTEnMeta || {},
    component: () => import("/usr/src/packages/kyc/pages/kyc/[type]/steps.vue")
  }
]
  },
  {
    name: "kyc-type___en",
    path: "/en/kyc/:type()",
    meta: _91type_93Zgq61ZsU9DMeta || {},
    component: () => import("/usr/src/packages/kyc/pages/kyc/[type].vue"),
    children: [
  {
    name: "kyc-type-group___en",
    path: ":group?",
    meta: _91_91group_93_934w1d1wdPGbMeta || {},
    component: () => import("/usr/src/packages/kyc/pages/kyc/[type]/[[group]].vue")
  },
  {
    name: "kyc-type-steps___en",
    path: "steps",
    meta: stepsHDNOePwTEnMeta || {},
    component: () => import("/usr/src/packages/kyc/pages/kyc/[type]/steps.vue")
  }
]
  },
  {
    name: "kyc-done___he",
    path: "/he/kyc/done",
    meta: donePxbdkf3XIxMeta || {},
    component: () => import("/usr/src/packages/kyc/pages/kyc/done.vue")
  },
  {
    name: "kyc-done___en",
    path: "/en/kyc/done",
    meta: donePxbdkf3XIxMeta || {},
    component: () => import("/usr/src/packages/kyc/pages/kyc/done.vue")
  },
  {
    name: "kyc___he",
    path: "/he/kyc",
    meta: indexlHo5lbSUxmMeta || {},
    component: () => import("/usr/src/packages/kyc/pages/kyc/index.vue")
  },
  {
    name: "kyc___en",
    path: "/en/kyc",
    meta: indexlHo5lbSUxmMeta || {},
    component: () => import("/usr/src/packages/kyc/pages/kyc/index.vue")
  },
  {
    name: "login___he",
    path: "/he/login",
    meta: loginYIEPQC6mhGMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/login.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: loginYIEPQC6mhGMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/login.vue")
  },
  {
    name: "partners___he",
    path: "/he/partners",
    meta: partnersnKP1uP9IicMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/partners.vue")
  },
  {
    name: "partners___en",
    path: "/en/partners",
    meta: partnersnKP1uP9IicMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/partners.vue")
  },
  {
    name: "passwordresetawait___he",
    path: "/he/passwordresetawait",
    meta: passwordresetawaitFAii2hbFCfMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/passwordresetawait.vue")
  },
  {
    name: "passwordresetawait___en",
    path: "/en/passwordresetawait",
    meta: passwordresetawaitFAii2hbFCfMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/passwordresetawait.vue")
  },
  {
    name: depotaPbp8tJMbrMeta?.name,
    path: "/he/pension/depot",
    meta: depotaPbp8tJMbrMeta || {},
    component: () => import("/usr/src/packages/pension/pages/pension/depot.vue"),
    children: [
  {
    name: "pension-depot-finqfull-companies-company___he",
    path: "finqfull/companies/:company()",
    meta: _91company_93iWeaW7O18lMeta || {},
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/companies/[company].vue")
  },
  {
    name: "pension-depot-finqfull-companies___he",
    path: "finqfull/companies",
    meta: index7V9IkVqMQaMeta || {},
    redirect: "/pension/depot",
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/companies/index.vue")
  },
  {
    name: "pension-depot-finqfull___he",
    path: "finqfull",
    meta: indexvOJnJJHuf3Meta || {},
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/index.vue")
  },
  {
    name: "pension-depot-finqfull-specialization-specialty___he",
    path: "finqfull/specialization/:specialty()",
    meta: _91specialty_93EMJ8NGR8qiMeta || {},
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/specialization/[specialty].vue")
  },
  {
    name: "pension-depot-finqfull-specialization___he",
    path: "finqfull/specialization",
    meta: index5EOUCdyNX8Meta || {},
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/specialization/index.vue")
  },
  {
    name: "pension-depot-finqfull-watch-fundHash___he",
    path: "finqfull/watch/:fundHash()",
    meta: _91fundHash_93EFQ29CzgCKMeta || {},
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/watch/[fundHash].vue")
  },
  {
    name: "pension-depot-finqfull-watch___he",
    path: "finqfull/watch",
    meta: indexiSIKEil1ZxMeta || {},
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/watch/index.vue")
  },
  {
    name: "pension-depot___he",
    path: "",
    component: () => import("/usr/src/packages/pension/pages/pension/depot/index.vue")
  },
  {
    name: "pension-depot-leading-funds-companies___he",
    path: "leading-funds/companies",
    meta: companiesOMujoLmf23Meta || {},
    component: () => import("/usr/src/packages/pension/pages/pension/depot/leading-funds/companies.vue")
  },
  {
    name: "pension-depot-leading-funds___he",
    path: "leading-funds",
    meta: indexufVtMGX5vzMeta || {},
    component: () => import("/usr/src/packages/pension/pages/pension/depot/leading-funds/index.vue")
  }
]
  },
  {
    name: depotaPbp8tJMbrMeta?.name,
    path: "/en/pension/depot",
    meta: depotaPbp8tJMbrMeta || {},
    component: () => import("/usr/src/packages/pension/pages/pension/depot.vue"),
    children: [
  {
    name: "pension-depot-finqfull-companies-company___en",
    path: "finqfull/companies/:company()",
    meta: _91company_93iWeaW7O18lMeta || {},
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/companies/[company].vue")
  },
  {
    name: "pension-depot-finqfull-companies___en",
    path: "finqfull/companies",
    meta: index7V9IkVqMQaMeta || {},
    redirect: "/pension/depot",
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/companies/index.vue")
  },
  {
    name: "pension-depot-finqfull___en",
    path: "finqfull",
    meta: indexvOJnJJHuf3Meta || {},
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/index.vue")
  },
  {
    name: "pension-depot-finqfull-specialization-specialty___en",
    path: "finqfull/specialization/:specialty()",
    meta: _91specialty_93EMJ8NGR8qiMeta || {},
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/specialization/[specialty].vue")
  },
  {
    name: "pension-depot-finqfull-specialization___en",
    path: "finqfull/specialization",
    meta: index5EOUCdyNX8Meta || {},
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/specialization/index.vue")
  },
  {
    name: "pension-depot-finqfull-watch-fundHash___en",
    path: "finqfull/watch/:fundHash()",
    meta: _91fundHash_93EFQ29CzgCKMeta || {},
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/watch/[fundHash].vue")
  },
  {
    name: "pension-depot-finqfull-watch___en",
    path: "finqfull/watch",
    meta: indexiSIKEil1ZxMeta || {},
    component: () => import("/usr/src/packages/pension/pages/pension/depot/finqfull/watch/index.vue")
  },
  {
    name: "pension-depot___en",
    path: "",
    component: () => import("/usr/src/packages/pension/pages/pension/depot/index.vue")
  },
  {
    name: "pension-depot-leading-funds-companies___en",
    path: "leading-funds/companies",
    meta: companiesOMujoLmf23Meta || {},
    component: () => import("/usr/src/packages/pension/pages/pension/depot/leading-funds/companies.vue")
  },
  {
    name: "pension-depot-leading-funds___en",
    path: "leading-funds",
    meta: indexufVtMGX5vzMeta || {},
    component: () => import("/usr/src/packages/pension/pages/pension/depot/leading-funds/index.vue")
  }
]
  },
  {
    name: portfoliosjxRGDJlRxLMeta?.name,
    path: "/he/portfolios",
    meta: portfoliosjxRGDJlRxLMeta || {},
    component: () => import("/usr/src/packages/portfolios/pages/portfolios.vue"),
    children: [
  {
    name: "portfolios-consult-details-pid___he",
    path: "consult/details/:pid?",
    meta: _91_91pid_93_93FAQHdTbWrNMeta || {},
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/consult/details/[[pid]].vue"),
    children: [
  {
    name: "portfolios-consult-details-pid-fid___he",
    path: ":fid?",
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/consult/details/[[pid]]/[[fid]].vue")
  }
]
  },
  {
    name: "portfolios-favorites___he",
    path: "favorites",
    meta: indexCuvpEcNESTMeta || {},
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/favorites/index.vue")
  },
  {
    name: "portfolios___he",
    path: "",
    meta: indexaZoVOhZwTVMeta || {},
    redirect: "/",
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/index.vue")
  },
  {
    name: "portfolios-lobby___he",
    path: "lobby",
    meta: lobbyVVzKDVXQ0XMeta || {},
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/lobby.vue")
  },
  {
    name: storenavOVDyujfMeta?.name,
    path: "store",
    meta: storenavOVDyujfMeta || {},
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/store.vue"),
    children: [
  {
    name: "portfolios-store-details-pid___he",
    path: "details/:pid?",
    meta: _91_91pid_93_935JUUTaXGU6Meta || {},
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/store/details/[[pid]].vue"),
    children: [
  {
    name: "portfolios-store-details-pid-fid___he",
    path: ":fid?",
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/store/details/[[pid]]/[[fid]].vue")
  }
]
  },
  {
    name: "portfolios-store___he",
    path: "",
    meta: indexetdbuDprAYMeta || {},
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/store/index.vue")
  }
]
  }
]
  },
  {
    name: portfoliosjxRGDJlRxLMeta?.name,
    path: "/en/portfolios",
    meta: portfoliosjxRGDJlRxLMeta || {},
    component: () => import("/usr/src/packages/portfolios/pages/portfolios.vue"),
    children: [
  {
    name: "portfolios-consult-details-pid___en",
    path: "consult/details/:pid?",
    meta: _91_91pid_93_93FAQHdTbWrNMeta || {},
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/consult/details/[[pid]].vue"),
    children: [
  {
    name: "portfolios-consult-details-pid-fid___en",
    path: ":fid?",
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/consult/details/[[pid]]/[[fid]].vue")
  }
]
  },
  {
    name: "portfolios-favorites___en",
    path: "favorites",
    meta: indexCuvpEcNESTMeta || {},
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/favorites/index.vue")
  },
  {
    name: "portfolios___en",
    path: "",
    meta: indexaZoVOhZwTVMeta || {},
    redirect: "/",
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/index.vue")
  },
  {
    name: "portfolios-lobby___en",
    path: "lobby",
    meta: lobbyVVzKDVXQ0XMeta || {},
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/lobby.vue")
  },
  {
    name: storenavOVDyujfMeta?.name,
    path: "store",
    meta: storenavOVDyujfMeta || {},
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/store.vue"),
    children: [
  {
    name: "portfolios-store-details-pid___en",
    path: "details/:pid?",
    meta: _91_91pid_93_935JUUTaXGU6Meta || {},
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/store/details/[[pid]].vue"),
    children: [
  {
    name: "portfolios-store-details-pid-fid___en",
    path: ":fid?",
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/store/details/[[pid]]/[[fid]].vue")
  }
]
  },
  {
    name: "portfolios-store___en",
    path: "",
    meta: indexetdbuDprAYMeta || {},
    component: () => import("/usr/src/packages/portfolios/pages/portfolios/store/index.vue")
  }
]
  }
]
  },
  {
    name: "pricing___he",
    path: "/he/pricing",
    component: () => import("/usr/src/packages/pricing/pages/pricing.vue"),
    children: [
  {
    name: "pricing-productId___he",
    path: ":productId?",
    meta: _91_91productId_93_93hqPFitOqkYMeta || {},
    component: () => import("/usr/src/packages/pricing/pages/pricing/[[productId]].vue")
  }
]
  },
  {
    name: "pricing___en",
    path: "/en/pricing",
    component: () => import("/usr/src/packages/pricing/pages/pricing.vue"),
    children: [
  {
    name: "pricing-productId___en",
    path: ":productId?",
    meta: _91_91productId_93_93hqPFitOqkYMeta || {},
    component: () => import("/usr/src/packages/pricing/pages/pricing/[[productId]].vue")
  }
]
  },
  {
    name: "privacy___he",
    path: "/he/privacy",
    meta: privacyW7FWgOcv9fMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/privacy.vue")
  },
  {
    name: "privacy___en",
    path: "/en/privacy",
    meta: privacyW7FWgOcv9fMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/privacy.vue")
  },
  {
    name: "profile___he",
    path: "/he/profile",
    meta: profilezv8G5W5pJQMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/profile.vue"),
    children: [
  {
    name: "profile-info___he",
    path: "info",
    meta: infoFL8Bz5DpZJMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/profile/info.vue")
  },
  {
    name: investmentsKaKYZu24t3Meta?.name,
    path: "investments",
    meta: investmentsKaKYZu24t3Meta || {},
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments.vue"),
    children: [
  {
    name: "profile-investments-funds-id___he",
    path: "funds/:id?",
    meta: _91_91id_93_938xxf54ZtMqMeta || {},
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments/funds/[[id]].vue")
  },
  {
    name: "profile-investments___he",
    path: "",
    meta: indexmc0Fx1pJFqMeta || {},
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments/index.vue")
  },
  {
    name: "profile-investments-messages___he",
    path: "messages",
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments/messages.vue")
  },
  {
    name: "profile-investments-reports___he",
    path: "reports",
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments/reports.vue")
  },
  {
    name: "profile-investments-transactions___he",
    path: "transactions",
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments/transactions.vue")
  }
]
  }
]
  },
  {
    name: "profile___en",
    path: "/en/profile",
    meta: profilezv8G5W5pJQMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/profile.vue"),
    children: [
  {
    name: "profile-info___en",
    path: "info",
    meta: infoFL8Bz5DpZJMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/profile/info.vue")
  },
  {
    name: investmentsKaKYZu24t3Meta?.name,
    path: "investments",
    meta: investmentsKaKYZu24t3Meta || {},
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments.vue"),
    children: [
  {
    name: "profile-investments-funds-id___en",
    path: "funds/:id?",
    meta: _91_91id_93_938xxf54ZtMqMeta || {},
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments/funds/[[id]].vue")
  },
  {
    name: "profile-investments___en",
    path: "",
    meta: indexmc0Fx1pJFqMeta || {},
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments/index.vue")
  },
  {
    name: "profile-investments-messages___en",
    path: "messages",
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments/messages.vue")
  },
  {
    name: "profile-investments-reports___en",
    path: "reports",
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments/reports.vue")
  },
  {
    name: "profile-investments-transactions___en",
    path: "transactions",
    component: () => import("/usr/src/apps/finq-finance/src/pages/profile/investments/transactions.vue")
  }
]
  }
]
  },
  {
    name: "signup___he",
    path: "/he/signup",
    meta: signupD0zLV4v9noMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/signup.vue")
  },
  {
    name: "signup___en",
    path: "/en/signup",
    meta: signupD0zLV4v9noMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/signup.vue")
  },
  {
    name: stocksYNZBSUl7vEMeta?.name,
    path: "/he/stocks",
    meta: stocksYNZBSUl7vEMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks.vue"),
    children: [
  {
    name: depotozn4ObMpKAMeta?.name,
    path: "depot",
    meta: depotozn4ObMpKAMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/depot.vue"),
    children: [
  {
    name: "stocks-depot-finqfull___he",
    path: "finqfull",
    meta: finqfull2rvSY7bCOYMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/depot/finqfull.vue")
  },
  {
    name: "stocks-depot___he",
    path: "",
    meta: indexD0CTvv9iYrMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/depot/index.vue")
  }
]
  },
  {
    name: "stocks___he",
    path: "",
    component: () => import("/usr/src/packages/stocks/pages/stocks/index.vue")
  },
  {
    name: portfolioszi0VENqbE6Meta?.name,
    path: "portfolios",
    meta: portfolioszi0VENqbE6Meta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios.vue"),
    children: [
  {
    name: _91portfolioType_93Lz5OTVGOUJMeta?.name,
    path: ":portfolioType()",
    meta: _91portfolioType_93Lz5OTVGOUJMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType].vue"),
    children: [
  {
    name: "stocks-portfolios-portfolioType___he",
    path: "",
    meta: indexPDJrGGTxBuMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/index.vue")
  },
  {
    name: "stocks-portfolios-portfolioType-returns___he",
    path: "returns",
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/returns.vue")
  },
  {
    name: "stocks-portfolios-portfolioType-transactions___he",
    path: "transactions",
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/transactions.vue")
  }
]
  },
  {
    name: "stocks-portfolios-details___he",
    path: "details",
    meta: detailsxa2DsXpMPrMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/details.vue"),
    children: [
  {
    name: "stocks-portfolios-details-id___he",
    path: ":id()",
    meta: _91id_93xL6fM6Wr70Meta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/details/[id].vue")
  }
]
  },
  {
    name: "stocks-portfolios___he",
    path: "",
    meta: indexsNto0OwKRYMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/index.vue")
  },
  {
    name: sectorsLTaJFptYjnMeta?.name,
    path: "sectors",
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/sectors.vue"),
    children: [
  {
    name: "stocks-portfolios-sectors-sectorId___he",
    path: ":sectorId()",
    meta: _91sectorId_93oZzOWsL8HcMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/sectors/[sectorId].vue")
  },
  {
    name: "stocks-portfolios-sectors___he",
    path: "",
    meta: indexvuxyYCf9xrMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/sectors/index.vue")
  }
]
  },
  {
    name: snp500mPrgrMS26sMeta?.name,
    path: "snp500",
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500.vue"),
    children: [
  {
    name: "stocks-portfolios-snp500___he",
    path: "",
    meta: indexRIwpw4c1lXMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500/index.vue")
  },
  {
    name: "stocks-portfolios-snp500-sectors___he",
    path: "sectors",
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500/sectors.vue")
  }
]
  }
]
  },
  {
    name: "stocks-search___he",
    path: "search",
    meta: searchT1B2Y8KjdfMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/search.vue")
  },
  {
    name: "stocks-top20___he",
    path: "top20",
    meta: top20QVDMEgSsSmMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/top20.vue")
  }
]
  },
  {
    name: stocksYNZBSUl7vEMeta?.name,
    path: "/en/stocks",
    meta: stocksYNZBSUl7vEMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks.vue"),
    children: [
  {
    name: depotozn4ObMpKAMeta?.name,
    path: "depot",
    meta: depotozn4ObMpKAMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/depot.vue"),
    children: [
  {
    name: "stocks-depot-finqfull___en",
    path: "finqfull",
    meta: finqfull2rvSY7bCOYMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/depot/finqfull.vue")
  },
  {
    name: "stocks-depot___en",
    path: "",
    meta: indexD0CTvv9iYrMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/depot/index.vue")
  }
]
  },
  {
    name: "stocks___en",
    path: "",
    component: () => import("/usr/src/packages/stocks/pages/stocks/index.vue")
  },
  {
    name: portfolioszi0VENqbE6Meta?.name,
    path: "portfolios",
    meta: portfolioszi0VENqbE6Meta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios.vue"),
    children: [
  {
    name: _91portfolioType_93Lz5OTVGOUJMeta?.name,
    path: ":portfolioType()",
    meta: _91portfolioType_93Lz5OTVGOUJMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType].vue"),
    children: [
  {
    name: "stocks-portfolios-portfolioType___en",
    path: "",
    meta: indexPDJrGGTxBuMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/index.vue")
  },
  {
    name: "stocks-portfolios-portfolioType-returns___en",
    path: "returns",
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/returns.vue")
  },
  {
    name: "stocks-portfolios-portfolioType-transactions___en",
    path: "transactions",
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/[portfolioType]/transactions.vue")
  }
]
  },
  {
    name: "stocks-portfolios-details___en",
    path: "details",
    meta: detailsxa2DsXpMPrMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/details.vue"),
    children: [
  {
    name: "stocks-portfolios-details-id___en",
    path: ":id()",
    meta: _91id_93xL6fM6Wr70Meta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/details/[id].vue")
  }
]
  },
  {
    name: "stocks-portfolios___en",
    path: "",
    meta: indexsNto0OwKRYMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/index.vue")
  },
  {
    name: sectorsLTaJFptYjnMeta?.name,
    path: "sectors",
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/sectors.vue"),
    children: [
  {
    name: "stocks-portfolios-sectors-sectorId___en",
    path: ":sectorId()",
    meta: _91sectorId_93oZzOWsL8HcMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/sectors/[sectorId].vue")
  },
  {
    name: "stocks-portfolios-sectors___en",
    path: "",
    meta: indexvuxyYCf9xrMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/sectors/index.vue")
  }
]
  },
  {
    name: snp500mPrgrMS26sMeta?.name,
    path: "snp500",
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500.vue"),
    children: [
  {
    name: "stocks-portfolios-snp500___en",
    path: "",
    meta: indexRIwpw4c1lXMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500/index.vue")
  },
  {
    name: "stocks-portfolios-snp500-sectors___en",
    path: "sectors",
    component: () => import("/usr/src/packages/stocks/pages/stocks/portfolios/snp500/sectors.vue")
  }
]
  }
]
  },
  {
    name: "stocks-search___en",
    path: "search",
    meta: searchT1B2Y8KjdfMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/search.vue")
  },
  {
    name: "stocks-top20___en",
    path: "top20",
    meta: top20QVDMEgSsSmMeta || {},
    component: () => import("/usr/src/packages/stocks/pages/stocks/top20.vue")
  }
]
  },
  {
    name: "subscribe-callback___he",
    path: "/he/subscribe/callback",
    meta: callback0Nh93rez4dMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/subscribe/callback.vue")
  },
  {
    name: "subscribe-callback___en",
    path: "/en/subscribe/callback",
    meta: callback0Nh93rez4dMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/subscribe/callback.vue")
  },
  {
    name: "termsofuse___he",
    path: "/he/termsofuse",
    meta: termsofusel5AzRHu0H7Meta || {},
    component: () => import("/usr/src/apps/base/src/pages/termsofuse.vue")
  },
  {
    name: "termsofuse___en",
    path: "/en/termsofuse",
    meta: termsofusel5AzRHu0H7Meta || {},
    component: () => import("/usr/src/apps/base/src/pages/termsofuse.vue")
  },
  {
    name: "trademark-copyright-intellectual-property___he",
    path: "/he/trademark-copyright-intellectual-property",
    meta: trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/trademark-copyright-intellectual-property.vue")
  },
  {
    name: "trademark-copyright-intellectual-property___en",
    path: "/en/trademark-copyright-intellectual-property",
    meta: trademark_45copyright_45intellectual_45propertyMbnBQ4r9vbMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/trademark-copyright-intellectual-property.vue")
  },
  {
    name: "verify___he",
    path: "/he/verify",
    meta: verify2fhYk5060fMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/verify.vue")
  },
  {
    name: "verify___en",
    path: "/en/verify",
    meta: verify2fhYk5060fMeta || {},
    component: () => import("/usr/src/apps/base/src/pages/verify.vue")
  }
]